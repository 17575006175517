<template>
  <router-view :key ='$route.params'/>
</template>

<script>
import "./assets/css/style.css"
// import Header_Vaohang from './components/Header.vue'
// import Footer_Vaohang from './components/Footer.vue'

export default {
  name: 'App',
  components: {
  
  }
}
</script>

<style>
</style>
