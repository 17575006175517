import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'

const app = createApp(App)

var domain_web = 'https://f8game.click/api/'
app.config.globalProperties.api_previewLink = domain_web + 'link'
app.config.globalProperties.domain = 'f8live.tv'

app.use(router)
app.use(VueAxios, axios)

app.mount('#app')
