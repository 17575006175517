import { createRouter, createWebHistory } from 'vue-router'

import Home_vaohang from '@/components/Home.vue'

const routes = [
    {
        path: '/',
        name: 'Home_vaohang',
        component: Home_vaohang
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

export default router